import React from "react";
import "./Contact.scss";
import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";
import Form from "../../components/Form/Form.component";

const Contact = () => {
  return (
    <>
      <Layout heading={<Banner title="Kontakt" className="contact-banner"/>} menuId={7}>
        <div className="contact default-margin">
          <div className="description">
            <div className="row">
              <div className="col-sm-6">
                <b>Sprachen und Übersetzungszentrum Kassel</b>
                <div className="address">
                  Garde-du-Corps-Straße 1<br />
                  34117 Kassel<br/>
                  Deutschland<br/>
                  <a className="google-link" href="https://www.google.com/maps/place/Sprachen-+und+%C3%9Cbersetzungszentrum+Kassel/@51.3136,9.49123,15z/data=!4m5!3m4!1s0x0:0x3edb3adee3fc04fe!8m2!3d51.3136!4d9.49123" target="blank">
                    <span>Google Maps</span>
                    <span><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M17.001 20h-11a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h4v2h-4v11h11v-4h2v4a2 2 0 0 1-2 2zm-5.3-6.293l-1.41-1.414L16.584 6h-3.583V4h7v7h-2V7.415l-6.3 6.292z" fill="currentColor"/></g></svg></span>
                  </a>
                  <br/>
                  <a className={"contact-item"} href="mailto:info@suz-kassel.de">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1rem" height="1rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                        <g fill="none">
                          <path d="M4 3a2 2 0 0 0-2 2v.201l6 3.231l6-3.23V5a2 2 0 0 0-2-2H4z" fill="currentColor"/>
                          <path d="M14 6.337L8.237 9.44a.5.5 0 0 1-.474 0L2 6.337V11a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.337z" fill="currentColor"/>
                        </g>
                      </svg>
                    </span>
                    <span>info@suz-kassel.de</span>
                  </a><br/>
                  <a className={"contact-item"} href="tel:+495615214868">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36">
                        <path class="clr-i-solid clr-i-solid-path-1" d="M15.22 20.64a20.37 20.37 0 0 0 7.4 4.79l3.77-3a.67.67 0 0 1 .76 0l7 4.51a2 2 0 0 1 .33 3.18l-3.28 3.24a4 4 0 0 1-3.63 1.07a35.09 35.09 0 0 1-17.15-9A33.79 33.79 0 0 1 1.15 8.6a3.78 3.78 0 0 1 1.1-3.55l3.4-3.28a2 2 0 0 1 3.12.32L13.43 9a.63.63 0 0 1 0 .75l-3.07 3.69a19.75 19.75 0 0 0 4.86 7.2z" fill="currentColor"/>
                      </svg>
                    </span>
                    <span>+49 561 521 48 68</span>
                  </a>
                </div>
              </div>
              <div className="col-sm-6 opening">
                  <b>Öffnungszeiten</b>
                  <div className="row">
                    <div className="col-4 col-md-4 col-lg-3">Montag</div>
                    <div className="col-8 col-md-8 col-lg-9">08:30 - 12:00 Uhr<br/>14:00 - 16:00 Uhr</div>

                    <div className="col-4 col-md-4 col-lg-3">Dienstag</div>
                    <div className="col-8 col-md-8 col-lg-9">08:30 - 12:00 Uhr<br/>14:00 - 18:00 Uhr</div>

                    <div className="col-4 col-md-4 col-lg-3">Mittwoch</div>
                    <div className="col-8 col-md-8 col-lg-9">08:30 - 13:00 Uhr</div>

                    <div className="col-4 col-md-4 col-lg-3">Donnerstag</div>
                    <div className="col-8 col-md-8 col-lg-9">08:30 - 12:00 Uhr<br/>14:00 - 18:00 Uhr</div>

                    <div className="col-4 col-md-4 col-lg-3">Freitag</div>
                    <div className="col-8 col-md-8 col-lg-9">08:30 - 13:00 Uhr</div>

                    <div className="col-12 text-primary"><b>An Feier- und Brückentagen geschlossen</b></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Contact;
