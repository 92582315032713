import React, { useEffect } from "react";
import {Link} from "react-router-dom";
import "./Navbar.scss";
import Suez from "../../assets/images/suez.png";

const NavBar = () => {

    function setMarginTop() {
        let layout = document.getElementsByClassName("layout")[0];
        let nav = document.getElementsByClassName("nav")[0];

        if (window.innerWidth < 768) {
            if ( !!layout && !!nav )
            {
                layout.style.marginTop = nav.getBoundingClientRect().height + 'px';
            }
        } else if ( !!layout ) {
            layout.style.marginTop = 0;
        }
    }

    window.addEventListener('resize', function() {
        if (window.innerWidth > 768) {
            closeMenu();
        }

        setMarginTop();
    });

    function menuAction(element, oldClass, newClass) {
        element.classList.replace(oldClass, newClass);
    }

    function openMenu() {
        menuAction(document.getElementById("backdrop"), "hide", "show");
        menuAction(document.getElementById("menuToggler"), "close", "open");
        menuAction(document.getElementById("sideBarContainer"), "close", "open");
    }

    function closeMenu() {
        menuAction(document.getElementById("backdrop"), "show", "hide");
        menuAction(document.getElementById("menuToggler"), "open", "close");
        menuAction(document.getElementById("sideBarContainer"), "open", "close");
    }

    function toggleMenu() {
        if (document.getElementById("sideBarContainer").classList.contains("open")) {
            closeMenu();
        } else {
            openMenu();
        }
    };

    useEffect(() => {
        setMarginTop();
    });

    useEffect(() => {
        document.getElementById("backdrop")?.addEventListener("click", function () {
            toggleMenu();
        });
    }, [document.getElementById("backdrop")]);

    return (
        <div className={"nav"}>
          <div className={"container"}>
            <div className={"brand-wrapper"}>
                <a href={"/"}>
                    <div>
                        <img src={Suez} alt={"Suez"} />
                    </div>
                </a>
            </div>
            <div className="main-menu">
                <div onClick={toggleMenu} className="menu-toggler close" id="menuToggler">
                    <div className="menu-burger"></div>
                </div>
                <ul className="menu-list">
                    <li>
                        <a href="mailto:info@suz-kassel.de">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1rem" height="1rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                                    <g fill="none">
                                        <path d="M4 3a2 2 0 0 0-2 2v.201l6 3.231l6-3.23V5a2 2 0 0 0-2-2H4z" fill="currentColor"/>
                                        <path d="M14 6.337L8.237 9.44a.5.5 0 0 1-.474 0L2 6.337V11a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.337z" fill="currentColor"/>
                                    </g>
                                </svg>
                            </span>
                            <span>info@suz-kassel.de</span>
                        </a>
                        <a href="tel:+495615214868">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 36 36">
                                    <path d="M15.22 20.64a20.37 20.37 0 0 0 7.4 4.79l3.77-3a.67.67 0 0 1 .76 0l7 4.51a2 2 0 0 1 .33 3.18l-3.28 3.24a4 4 0 0 1-3.63 1.07a35.09 35.09 0 0 1-17.15-9A33.79 33.79 0 0 1 1.15 8.6a3.78 3.78 0 0 1 1.1-3.55l3.4-3.28a2 2 0 0 1 3.12.32L13.43 9a.63.63 0 0 1 0 .75l-3.07 3.69a19.75 19.75 0 0 0 4.86 7.2z" fill="currentColor"/>
                                </svg>
                            </span>
                            <span>+49 561 521 48 68</span>
                        </a>
                    </li>
                    <li>
                        <Link to={"/contact"}>Kontakt</Link>
                    </li>
                </ul>
            </div>
          </div>
        </div>
  );
};

export default NavBar;
