import React from "react";
import "./Footer.scss";
import {Link} from "react-router-dom";

const Footer = () => {
  return (
    <div className={"footer"}>
      <div className={"container"}>
        <div className={"row"}>
          <div className={"col-6 col-md-4 footer-row"}>
            <span className="footer-row-title small-title">Sprachen- und Übersetzungszentrum</span>
            <ul>
              <li>Garde-du-Corps-Str. 1</li>
              <li>34117 Kassel</li>
              <li className="google-link">
                <a href="https://www.google.com/maps/place/Sprachen-+und+%C3%9Cbersetzungszentrum+Kassel/@51.3136,9.49123,15z/data=!4m5!3m4!1s0x0:0x3edb3adee3fc04fe!8m2!3d51.3136!4d9.49123" target="blank">
                  <span>Google Maps</span>
                  <span><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M17.001 20h-11a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h4v2h-4v11h11v-4h2v4a2 2 0 0 1-2 2zm-5.3-6.293l-1.41-1.414L16.584 6h-3.583V4h7v7h-2V7.415l-6.3 6.292z" fill="currentColor"/></g></svg></span>
                </a>
              </li>
            </ul>
          </div>
          <div className={"col-6 col-md-3 footer-row"}>
            <span className="footer-row-title">Rechtliches</span>
            <ul>
              <li>
                <Link to={"/legal/gtc"}>AGB</Link>
              </li>
              <li>
                <Link to={"/legal/imprint"}>Impressum</Link>
              </li>
              <li>
                <Link to={"/legal/data-protection"}>Datenschutz</Link>
              </li>
            </ul>
          </div>

          <div className={"col-6 col-md-3 footer-row"}>
            <span className="footer-row-title">Informationen</span>
            <ul>
              <li>
                <Link to={"/contact"}>Kontakt</Link>
              </li>
              <li>
                <Link to={"/about-us"}>Über uns</Link>
              </li>
            </ul>
          </div>

          <div className={"col-6 col-md-2 footer-row"}>
            <span className="footer-row-title">Social Media</span>
            <ul>
              <li>
                <a href="https://www.facebook.com/suz.ubersetzungszentrum" title="facebook" target="blank">
                    <svg width="50" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 30">
                    <rect width="50" height="30" rx="3"></rect>
                    <polygon points="29.98 16.9 19.41 16.9 19.41 13.1 30.59 13.1 29.98 16.9" fill="#fff"></polygon>
                    <path d="M27,25.18V10.28a1.65,1.65,0,0,1,1.65-1.65l2,0V5.12L27.69,5A4.93,4.93,0,0,0,22.77,10V25.18Z" fill="#fff"></path>
                  </svg>
                </a>
              </li>
            </ul>
          </div>

        </div>

        <div className="copyright">
            <span>© Copyright 2023 SÜZ - Alle Rechte vorbehalten.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
