import React from "react";
import {Link} from "react-router-dom";
import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";

import "./Prices.scss";

const Prices = () => {
  return (
    <>
      <Layout heading={<Banner title="Preise" className="prices-banner"/>} menuId={5}>
        <div className="prices">
                <div className="course">Integrationskurse (Alphabetisierungskurse + allg. Integrationskurse)</div>

              <div className={"row list-item"}>
                <div className={"col-7 col-sm-7 price-col"}>Arbeitslose mit Arbeitslosengeld II (SGB II)</div>
                <div className={"col-5 col-sm-5 price-col fee"}>Kostenlos</div>
              </div>

              <div className={"row list-item"}>
                <div className={"col-7 col-sm-7 price-col"}>Sozialhilfeempfänger mit Sozialhilfe SGB XII</div>
                <div className={"col-5 col-sm-5 price-col fee"}>Kostenlos</div>
              </div>

              <div className={"row list-item"}>
                <div className={"col-7 col-sm-7 price-col"}>Erwerbstätige - (Teilzahler)</div>
                <div className={"col-5 col-sm-5 price-col fee"}><span>2,29 EUR</span> / Unterrichtseinheit</div>
              </div>

              <div className={"row list-item"}>
                <div className="col-7 price-col">Selbstzahler</div>
                <div className={"col-5 price-col fee"}><span>3,50 EUR</span> / Unterrichtseinheit</div>
              </div>

              <div className={"course"}>Andere Kurse</div>

              <div className={"row list-item-course"}>
                <div className="col-7 price-col">Mittelstufe (B2)</div>
                <div className={"col-5 price-col fee"}><span>4,00 EUR</span> / Unterrichtseinheit</div>
              </div>

              <div className={"row list-item-course"}>
                <div className="col-7 price-col">DSH (C1)</div>
                <div className={"col-5 price-col fee"}><span>4,00 EUR</span> / Unterrichtseinheit</div>
              </div>

              <div className={"row list-item-course"}>
                <div className="col-7 price-col">TestDaf</div>
                <div className={"col-5 price-col fee"}><span>4,00 EUR</span> / Unterrichtseinheit</div>
              </div>

              <div className={"row list-item-course"}>
                <div className="col-7 price-col">Studiumkollegsprüfung</div>
                <div className={"col-5 price-col fee"}><span>4,00 EUR</span> / Unterrichtseinheit</div>
              </div>

              <div className={"row"}>
                <div className={"col-12 course"}>Anmeldungen aus dem Ausland: <span className={"text-primary"}>50,00 EUR</span> Verwaltungsgebühr</div>
              </div>
        </div>

        <p>Für weitere Informationen nehmen Sie bitte <Link to="/contact">Kontakt</Link> mit uns auf.</p>
      </Layout>
    </>
  );
};

export default Prices;
