import React from "react";

import "./ToTopButton.scss";

const ToTopButton = () => {

  	function toTop() {
		window.scrollTo({
			top: 0,
	  		left: 0,
	  		behavior: 'smooth'
		}); 
  	}

  	return (
		<div className="toTop" onClick={toTop}>
	 		<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24">
	 			<g fill="none">
	 				<path d="M12 20V4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	 				<path d="M5 11l7-7l7 7" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	 			</g>
	 		</svg>
		</div>
  	)
};

export default ToTopButton;