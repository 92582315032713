import React from "react";
import {Link} from "react-router-dom";
import Suez from "../../../assets/images/suez.png";

import "./SideNavBar.scss";

const SideNavBar = ({menuId}) => {

	function closeMenu() {
		document.getElementById("menuToggler").classList.replace("open", "close");
        document.getElementById("sideBarContainer").classList.replace("open", "close");
        document.getElementById("backdrop").classList.replace("show", "hide");
	}

	return (
		<div>
			<div className="side-nav-bar">
				<div className="container">
					<ul>
						<li>
							<Link to={"/"} className={`home${menuId === 0 ? " active" : ""} home-img-link`} onClick={closeMenu}>
								<span>
									<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.5rem" height="1.25rem" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
										<path d="M16 8.5l1.53 1.53l-1.06 1.06L10 4.62l-6.47 6.47l-1.06-1.06L10 2.5l4 4v-2h2v4zm-6-2.46l6 5.99V18H4v-5.97zM12 17v-5H8v5h4z" fill="currentColor"/>
									</svg>
								</span>
							</Link>
							<div className={"brand-img-wrapper"}>
								<Link to={"/"} className={"home-img-link"} onClick={closeMenu}>
									<img src={Suez} alt={"Suez"} height="50px" />
								</Link>
							</div>
						</li>
						<li>
							<Link to={"/integration"} className={`${menuId === 1 ? "active" : ""}`} onClick={closeMenu}>
								<span>Integrationskurse</span>
							</Link>
						</li>
						<li>
							<Link to={"/course"} className={`${menuId === 2 ? "active" : ""}`} onClick={closeMenu}>
								<span>B2/C1 Kurse</span>
							</Link>
						</li>
						<li>
							<Link to={"/translation"} className={`${menuId === 3 ? "active" : ""}`} onClick={closeMenu}>
								<span>Übersetzungen</span>
							</Link>
						</li>
						<li>
							<Link to={"/exam"} className={`${menuId === 4 ? "active" : ""}`} onClick={closeMenu}>
								<span>Prüfungen</span>
							</Link>
						</li>
						<li>
							<Link to={"/prices"} className={`${menuId === 5 ? "active" : ""}`} onClick={closeMenu}>
								<span>Preise</span>
							</Link>
						</li>
						<li>
							<Link to={"/statement"} className={`${menuId === 6 ? "active" : ""}`} onClick={closeMenu}>
								<span>Leitbild</span>
							</Link>
						</li>
						<li>
							<Link to={"/contact"} className={`${menuId === 7 ? "active" : ""}`} onClick={closeMenu}>
								<span>Kontakt</span>
							</Link>
						</li>
						{/*<li>*/}
						{/*	<Link to={"/about-us"} className={`${menuId === 8 ? "active" : ""}`} onClick={closeMenu}>*/}
						{/*		Über uns*/}
						{/*	</Link>*/}
						{/*</li>*/}
					</ul>
				</div>
			</div>
		</div>

    );

};

export default SideNavBar;
