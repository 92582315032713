import React from "react";
import {Link} from "react-router-dom";

import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";
import Integration from "../../assets/images/integration.jpg";
import Course from "../../assets/images/course.jpg";
import Translation from "../../assets/images/translation.jpg";
import Exam from "../../assets/images/exam.jpg";
import Telc from "../../assets/images/telc.png";
import TQCert from "../../assets/images/tqcertifikation.jpg";
import Bamf from "../../assets/images/bamf.svg";
import Gast from "../../assets/images/gast.png";
import "./Startpage.scss";

const StartPage = () => {

  return (
    <>
      <Layout heading={<Banner className="start-banner" />} menuId={0} >
        <div className={"start default-margin"}>
          <div>
            <h1 className="text-primary">Sprachen- und Übersetzungszentrum Kassel</h1>
            <div className="row">
              <div className="col-12">
                  <div className="start-text description">
                    <p>Als Träger von Integrationskursen haben wir das Ziel, die deutsche Sprache und ein Verständnis für die deutsche Kultur zu vermitteln. Wir leben und treten für eine offene,
                    freie und gleichberechtigte Weltanschauung ein und fördern das Verständnis für die Unterschiede und die Gleichheit in Bezug auf
                    Herkunft, Alter, Geschlecht, Religion und Weltanschauung.</p>
                    <span className="text-primary"><b>Integration bedeutet für uns,</b> die Menschen da abzuholen, wo sie stehen und unsere Kommunikation und unsere Lernkultur an ihre Bedürfnisse anzupassen!</span>
                  </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-4 we-are-wrapper">
              <div className="we-are gast">
                <p className="text-primary"><b>Wir sind dein Prüfungszentrum!</b></p>
                <span><img className={"gast-img"} loading={"lazy"} src={Gast} height="45px" alt="gast" /></span>
                <span><img className={"telc-img"} loading={"lazy"} src={Telc} height="45px" alt="telc" /></span>
              </div>
            </div>
            <div className="col-12 col-sm-4 we-are-wrapper">
              <div className="we-are">
                <p className="text-primary"><b>Wir sind zertifiziert</b></p>
                <span><img loading={"lazy"} src={TQCert} height="65px" alt="TqCert-Zertifikat" /></span>
              </div>
            </div>
            <div className="col-12 col-sm-4 we-are-wrapper">
              <div className="we-are">
                <p className="text-primary"><b>Wir bilden nach dem Konzept des</b></p>
                <span><img loading={"lazy"} src={Bamf} height="65px" alt="Bundesamt für Migration und Flüchtlinge"/></span>
              </div>
            </div>
          </div>

          <div className="row cards">
            <div className="col col-sm-6 col-md-3 col-lg">
              <div className="card">
                <Link to={"/integration"}>
                  <div className="card-img" style={{backgroundImage: 'url(' + Integration + ')'}}>
                    <div className="card-title"><span>Integrationskurse</span></div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-break col-break-sm" />
            <div className="col col-sm-6 col-md-3 col-lg">
              <div className="card">
                <Link to={"/course"}>
                  <div className="card-img" style={{backgroundImage: 'url(' + Course + ')'}}>
                      <div className="card-title"><span>B2/C1 Kurse</span></div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-break col-break-md" />
            <div className="col col-sm-6 col-md-3 col-lg">
              <div className="card">
                <Link to={"/translation"}>
                  <div className="card-img" style={{backgroundImage: 'url(' + Translation + ')'}}>
                    <div className="card-title"><span>Übersetzungen</span></div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-break col-break-sm" />
            <div className="col col-sm-6 col-md-3 col-lg">
              <div className="card">
                <Link to={"/exam"}>
                  <div className="card-img" style={{backgroundImage: 'url(' + Exam + ')'}}>
                    <div className="card-title"><span>Prüfungen</span></div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-break col-break-md" />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default StartPage;
