import React from "react";
import {Link} from "react-router-dom";
import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";

import "./Translation.scss";

const Translation = () => {
	return (
		<Layout heading={<Banner title="Übersetzungen" additional={<span>vom vereidigtem Dolmetscher</span>} className="translation-banner"/>} menuId={3}>
			<div className="translation default-margin">
				<div className="description">
					<p>
						Dr. Ümit Özen ist vereidigter Dolmetscher für beide Sprachrichtungen (deutsch/türkisch und türkisch/deutsch) und hat langjährige Erfahrungen mit den verschiedensten Übersetzungen gemacht.
					</p>
					<p>
						Dr. Özen bietet für private als auch für behördliche Zwecke vereidigte Übersetzungen und Dolmetscherdienste auf professionellem Niveau an.
					</p>
				</div>
				<p>Falls Sie Interesse an unserem Dolmetscher- und Übersetzungsdienst haben, nehmen Sie gerne <Link to="/contact">Kontakt</Link> zu uns auf.</p>
			</div>
		</Layout>
	);
};

export default Translation;
