import React from "react";
import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";

import "./Statement.scss";

const Statement = () => {
	return (
		<Layout heading={<Banner title="Leitbild" className="statement-banner" additional={<span>des Sprachen- und Übersetzungszentrum</span>} />} menuId={6}>
			<div className="statement default-margin">
				<span className="sub-title text-primary"><b>Leitbild für AZAV</b></span>
				<div className="description">
					<p>
						<b>
							Die Akademie steht für Bedarfs- und kundenorientierte Aus- und Weiterbildung und garantierte Qualität in der Bildung.
						</b>
					</p>
					<p>
						Um unabhängig von den ausführenden Personen gleichbleibend gute Dienstleistungen anbieten zu können, verpflichten wir uns zur Einhaltung der nachfolgenden Grundsätze.
					</p>
					<span className="text-primary"><b>Kundenorientierung</b></span>
					<p>
						Die Kundenorientierung wird durch zielgruppenspezifische Leistungsangebote unter Berücksichtigung sich verändernder Kundenwünsche sichergestellt.
					</p>
					<p>
						Als Kunden werden von uns alle interessierten Parteien verstanden, dazu gehören die Teilnehmer, aber auch die auftraggebenden Unternehmen, die Gesellschaft im weitesten Sinne, die ein Interesse an der Einhaltung der Gesetze, dem Schutz der Umwelt und der Schonung von Ressourcen hat. Die Wirtschaft mit ihren marktgerechten Qualifikationsanforderungen, die Bundesagentur für Arbeit mit ihrem Anspruch der Eingliederung von Absolventen, selbst Mitarbeiter und Lieferanten sind interessierte Parteien und in diesem weit gefassten Begriff des Kunden mit einzubeziehen.
					</p>
					<p>
						In diesem Zusammenhang wird im Rahmen der vorhandenen Vorgehensweisen und Prozesse geprüft, ob die Forderungen und Erwartungen, die der Kunde an uns stellt, mit den vorhandenen Ressourcen (Ausstattung, Medien, Dozenten) erfüllt werden können. Dies versuchen wir durch eine stete Beobachtung der Prozesse im Rahmen von internen Audits und der Überwachung der täglichen Erledigung von anfallenden Aufgaben aufrecht zu erhalten und zu fördern. Die Zielsetzung, die hieraus erwächst, ist u. a. die nachhaltige Erhöhung der Kundenzufriedenheit.
					</p>

					<span className="text-primary"><b>Personal</b></span>
					<p>
						Wir setzen qualifiziertes hauptberufliches Personal in Leitung, Unterricht und Verwaltung ein, sowie qualifizierte haupt- und nebenamtlich Lehrende. Für haupt- und nebenberufliches Personal wird eine laufende fachliche und pädagogische Fortbildung durchgeführt.
					</p>

					<span className="text-primary"><b>Räumliche und sachliche Ausstattung</b></span>
					<p>
						Die Lernräume (Unterrichtsräume, Labore, Werkstätten) entsprechen nach Art und Ausstattung modernen erwachsenenpädagogischen und fachlichen Kriterien und sind den Zielgruppen angepasst. Die genutzten Lern- und Sozialräume und die sanitären Einrichtungen entsprechen den Anforderungen der Arbeitsstättenverordnung.
					</p>

					<span className="text-primary"><b>Bildungsangebot</b></span>
					<p>
						Potenzielle Lehrgangsteilnehmer erhalten von uns eindeutige Aussagen zur geforderten Eingangsqualifikation bzw. den Lernvoraussetzungen. Sie werden von uns persönlich beraten, um ein teilnehmerorientiertes und sachgerechtes Lernen zu ermöglichen. Bei Angeboten der beruflichen Weiterbildung werden aktuelle Arbeitsmarkt- und berufliche Qualifikationsanforderungen berücksichtigt sowie Praxisorientierung gewährleistet. Das Lehr- und Lernmaterial entspricht den fachdidaktischen Anforderungen und Standards. Allen Angeboten liegt ein didaktisch-methodisches Konzept zugrunde. Die Veranstaltungen sind auf Methodenvielfalt ausgerichtet. Erwachsenengerechte Lern- und Erfolgskontrollen sichern den Unterrichtserfolg.
					</p>

					<span className="text-primary"><b>Teilnehmer*innen</b></span>
					<p>
						Unsere Werbeaussagen sind wahrheitsgemäß und seriös. Bei unseren Lehrgangsangeboten herrscht Transparenz in Bezug auf: Zielsetzung, Teilnahmevoraussetzungen, Teilnahmebedingungen, Abschluss, Dauer, Ort, Preis, Zahl der Unterrichtsstunden, eingesetzte Methoden, Prüfungsmodalitäten, Geschäftsbedingungen.
					</p>
					<p>
						Bei der Entwicklung unserer Bildungsangebote nach dem Dritten Buch Sozialgesetzbuch (SGB III) berücksichtigen wir die Lage und die Entwicklung des Arbeitsmarktes und unterstützen die Teilnehmer*innen bei der Eingliederung in den regulären Arbeitsmarkt.
					</p>
				</div>
				<span className="sub-title text-primary"><b>Leitbild für das Bundesamt für Migrationen und Flüchtlinge</b></span>
				<div className="description">
					<p>
						Als Träger von Integrationskursen haben wir das Ziel, die deutsche Sprache und ein Verständnis für die deutsche Kultur zu vermitteln.					</p>
					<p>
						Auf unserem Weg gewinnen wir unsere Teilnehmer*innen als Freunde, indem wir ihnen empathisch, hilfsbereit und freundlich begegnen.					</p>
					<p>
						Wir leben und treten für eine offene, freie und gleichberechtigte Weltanschauung ein und fördern das Verständnis für die Unterschiede und die Gleichheit in Bezug auf Herkunft, Alter, Geschlecht, Religion und Weltanschauung.					</p>
					<p>
						Integration bedeutet für uns, die Menschen da abzuholen, wo sie stehen und unsere Kommunikation und unsere Lernkultur an ihre Bedürfnisse anzupassen.					</p>
					<p>
						Lehren bedeutet für uns die Menschen aufzufangen, ihnen eine angenehme Atmosphäre zu schaffen und Lehrkräfte zur Verfügung zu stellen, die über fachliche Kompetenzen hinaus soziale Kompetenzen mitbringen wie Empathie, Hilfsbereitschaft, Freundlichkeit und Engagement, damit sie mit Spaß den Lernprozess mitgestalten wollen und können.					</p>
					<p>
						Unsere Teilnehmer*innen sollen stets wissen, dass wir interessiert sind an ihrem Wissen, ihren Kompetenzen und ihrer Meinung, dass nur durch Austausch Verständnis und Lernen stattfinden kann.					</p>
					<p>
						Wertschätzung gegenüber jedem, unabhängig von Bildungsstand, Alter, Sexualität, Religion, Herkunft, sozialem Status ist unsere Überzeugung und das, was wir allen Teilnehmer*innen und jedem Gast vermitteln und vorleben.					</p>
					<p>
						Ein gesundes Unternehmen zeichnet sich für uns durch eine offene Kommunikationskultur und Verlässlichkeit aus. Durch Menschen, die ein Verständnis für gesunde Ernährung haben, die auf ihre Schlafhygiene achten und die Bedeutung von regelmäßigem Sport erkennen und leben.					</p>
					<p>
						Im regionalen Kontext beteiligen wir uns aktiv an Kooperationen und Netzwerken im Rahmen bildungspolitischer und sozialer Arbeit.					</p>
					<p>
						Ein rücksichtsvoller und nachhaltiger Umgang mit ökologischen und ökonomischen Ressourcen ist für uns eine der Hauptverantwortungen, die wir für all unsere derzeitigen und zukünftigen Teilnehmer und Beteiligten sehen. Auf diese Weise können wir langfristig einen Beitrag im Rahmen unserer Integrationsarbeit leisten und unsere Wertschätzung gegenüber der Natur und allen Beteiligten zum Ausdruck bringen.					</p>
				</div>
			</div>
		</Layout>
	);
};

export default Statement;
