import React from "react";
import {Link} from "react-router-dom";
import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";
//import Telc from "../../assets/images/telc.png";

import "./Exam.scss";

const Exam = () => {

	return (
		<Layout heading={<Banner title="Prüfungen" additional={<>
			<span>Wir sind dein Prüfungszentrum</span>
			{/*<span className="telc-wrapper">
				<img alt="Telc-Prüfungszentrum" src={Telc} />
			</span>*/}
		</>} className="exam-banner"/>} menuId={4}>
			<div className="exam">
				<div className="row exam-heading">
					<div className="col-5 col-sm-8 col-md-7 col-lg-8">Prüfung</div>
					{/*<div className="col-3 col-sm-3 col-lg-2">Termine</div>*/}
					<div className="col-4 col-sm-3 col-md-3 col-lg-2 col-lg-2 exam-row-price">Gebühr</div>
				</div>

				<div className="row exam-row">
					<div className="col-5 col-sm-8 col-md-7 col-lg-8">DTZ (A2/B1) – Deuschtest für Zuwanderer</div>
					{/*<div className="col-3 col-sm-3 col-lg-2">Jeden Samstag</div>*/}
					<div className="col-4 col-sm-3 col-md-3 col-lg-2 exam-row-price">110 EUR</div>
				</div>

				<div className="row exam-row">
					<div className="col-5 col-sm-8 col-md-7 col-lg-8">Einbürgerungstest / Leben in Deutschland</div>
					{/*<div className="col-3 col-sm-3 col-lg-2">Jeden Mittwoch</div>*/}
					<div className="col-4 col-sm-3 col-md-3 col-lg-2 exam-row-price">25 EUR</div>
				</div>

				<div className="row exam-row">
					<div className="col-5 col-sm-8 col-md-7 col-lg-8">Start Deutsch 1 / A1</div>
					{/*<div className="col-3 col-sm-3 col-lg-2">Bei Bedarf</div>*/}
					<div className="col-4 col-sm-3 col-md-3 col-lg-2 exam-row-price">110 EUR</div>
				</div>

				<div className="row exam-row">
					<div className="col-5 col-sm-8 col-md-7 col-lg-8">B2</div>
					{/*<div className="col-3 col-sm-3 col-lg-2">Bei Bedarf</div>*/}
					<div className="col-4 col-sm-3 col-md-3 col-lg-2 exam-row-price">200 EUR</div>
				</div>

				<div className="row exam-row">
					<div className="col-5 col-sm-8 col-md-7 col-lg-8">C1 Hochschule</div>
					{/*<div className="col-3 col-sm-3 col-lg-2">Bei Bedarf</div>*/}
					<div className="col-4 col-sm-3 col-md-3 col-lg-2 exam-row-price">230 EUR</div>
				</div>
			</div>
			<p>Bitte nehmen Sie <Link to="/contact">Kontakt</Link> mit uns auf, wenn Sie an einer Prüfung Interesse haben.</p>
		</Layout>
	);
};

export default Exam;
