import React from "react";
import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";

import "./AboutUs.scss";

const AboutUs = () => {
	return (
		<Layout heading={<Banner title="Über uns" className="about-us-banner"/>} menuId={8}>
			<div className="about-us default-margin">
				<div className="description">
					<p>Das Sprachen- und Übersetzungszentrum SÜZ in Kassel wurde 2002 von Dr. Ümit Özen gegründet. Seitdem haben wir uns zu Spezialisten in der sprachlichen Ausbildung Erwachsener entwickelt. Insbesondere im Bereich der Integrationskurse vom Bundesamt für Migration und Flüchtlinge (BAMF) (B1-Zertifkate) und im Bereich der Vorbereitung auf die universitären Deutschprüfungen wie DSH, TestDaF und Studienkollegsprüfung, genießt Herr Dr. Özen unter den Deutschlernerinnen/Deutschlernern in Kassel großes Ansehen.</p> 
					<p>Dr. Ümit Özen (Inhaber vom SÜZ und Dozent der Vorbereitungskurse) hat in Siegen die Fachrichtung „Sprachwissenschaft des Deutschen“ mit den Nebenfächern „Politik- und Literaturwissenschaften“ studiert. Im Rahmen seiner anschließenden Promotion beschäftigte er sich mit der Frage, wie Wörter, die in unterschiedlichen Sprachen mehr oder weniger ähnlich vorkommen (z.B. Radio, Sport, Politik, Demokratie, demokratisch) in einem „Deutsch als Fremdspracheunterricht (DaF)“ didaktisch sinnvoll eingesetzt werden könnten, um den Lernerfolg des Deutschunterrichtes zu optimieren.</p>
					<p>Nach seiner Promotion unterrichtete er zwei Semester Methodik und Didaktik an der Universität Siegen. Im Jahre 1999 kam er zur Universität Kassel und war hier im Sprachenzentrum im Bereich „Deutsche Sprachprüfung für den Hochschulzugang (DSH)“ bis Ende 2002 tätig. Er führte die DSH- Vorbereitungskurse und nahm die DSH- Prüfungen ab.</p>
					<p>Das Erfolgsrezept bei all seinen methodischen und didaktischen Ansätzen, wie auch bei der Leitung seines Zentrums lautet: freundliche Atmosphäre.</p>
					<p className="info-box">„Wenn die Lehrenden ein guter Freund von Deutschlernenden sind, so dass alle Lust haben am nächsten Tag wieder zur Schule zu kommen, dann gibt es keinen Grund erfolglos zu sein.“</p>
					<p>Dr. Ümit Özen</p>
				</div>
			</div>
		</Layout>
	);
};

export default AboutUs