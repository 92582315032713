import React, {useEffect} from "react";
import {Redirect, Switch, Route, useLocation} from "react-router-dom";
import ToTopButton from "../components/ToTopButton/ToTopButton.component";
import NavBar from "../components/Navbar/Navbar.component";
import Integration from "../container/Integration/Integration.component";
import Translation from "../container/Translation/Translation.component";
import Exam from "../container/Exam/Exam.component";
import Prices from "../container/Prices/Prices.component";
import Footer from "../components/Footer/Footer.component";
import StartPage from "../container/Startpage/Startpage.component";
import Course from "../container/Course/Course.component";
import Contact from "../container/Contact/Contact.component";
import AboutUs from "../container/AboutUs/AboutUs.component";
import Statement from "../container/Statement/Statement.component";
import Gtc from "../container/Legal/Gtc/Gtc.component";
import DataProtection from "../container/Legal/DataProtection/DataProtection.component";
import Imprint from "../container/Legal/Imprint/Imprint.component";


const Router = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  }, [pathname]);

  return (
    <div className={`layout ${pathname === "/" ? "home" : ""}${pathname.includes("legal") ? "legal" : ""}`}>
      <NavBar />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route exact path="/">
          <StartPage />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/course">
          <Course />
        </Route>
        <Route path="/integration">
          <Integration />
        </Route>
        <Route path="/translation">
          <Translation />
        </Route>
        <Route path="/exam">
          <Exam />
        </Route>
        <Route path="/prices">
          <Prices />
        </Route>
        <Route path="/statement">
          <Statement />
        </Route>
        <Route path="/about-us">
          <AboutUs />
        </Route>
        <Route path="/legal/gtc">
          <Gtc />
        </Route>
        <Route path="/legal/data-protection">
          <DataProtection />
        </Route>
        <Route path="/legal/imprint">
          <Imprint />
        </Route>
      </Switch>
      <Footer />
      <ToTopButton/>
      <div className="back-drop hide" id="backdrop"/>
    </div>
  );
};

export default Router;
