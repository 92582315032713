import React from "react";

import "./Banner.scss";

const Banner = ({title = "", className="", additional = "", imageUrl = ""}) => {
	return (
		<div className={`banner banner-img ${className}`} style={{'backgroundImage': imageUrl}}>
			<div className="bg-gradient"/>
			<div className="container">
				<div className="banner-content">
					{ title && <h1>{title}</h1>}
					{ additional }
				</div>
			</div>
		</div>)
}

export default Banner;