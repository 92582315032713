import React from "react";
import Layout from "../../../components/Layout/Layout.component";

import "./Gtc.scss";
import "./../Legal.scss";
import Banner from "../../../components/Banner/Banner.component";

const Gtc = () => {
	return (
		<Layout heading={<Banner className="legal-banner" />}>
			<div className="gtc default-margin">
				<h1>Allgemeine Geschäftsbedingungen</h1>
				<div>
					<ol>
						<li>Die Anmeldung zu einem Kurs erfolgt im Büro des Sprachen- und Übersetzungszentrums SÜZ, Garde-du-Corps-Str-1, 34117 Kassel. Bei Anmeldungen aus dem Ausland durch Zusendung eines Faxes mit Nachweis dass die Kursgebühr bereits überwiesen ist.</li>
						<li>Die Kursgebühren sind grundsätzlich vor Beginn eines Kurses entweder in bar, oder per Überweisung zu entrichten.</li>
						<li>Mit der Zahlung der Kursgebühren wird ein Platz reserviert. Die Anmeldung gilt dann als verbindlich. Bei einem Rücktritt vor Kursbeginn wird der gezahlte Betrag bis auf eine Verwaltungsgebühr von 50,00 Euro zurückerstattet. Ein Rücktritt nach dem Kursbeginn ist nicht möglich. Bereits gezahlte Gebühren werden nicht erstattet. Gleiches gilt für eine Unterbrechung oder einen Abbruch des Kurses seitens des Teilnehmers/der Teilnehmerin aus persönlichen Gründen.</li>
						<li>Bei Auslandsanmeldungen kann eine Stornierung nur dann erfolgen, wenn eine Erteilung des Einreisevisums von der jeweiligen Botschaft abgelehnt wurde. In diesem Fall muss der Ablehnungsbescheid und die Anmeldungsunterlagen (Anmeldebescheingung und Quittung) im Original vorgelegt werden, wenn eine Stornierung anerkannt werden soll. In diesem Fall sind die Kosten, die durch die  Stornierung entstanden sind, und eine Verwaltungsgebühr von 50 Euro von der gezahlten Kursgebühr abzurechnen.</li>
						<li>Für den Fall, dass ein angekündigter Kurs wegen zu geringer Teilnehmerzahl, Krankheit der Lehrkraft o.ä. nicht zu dem gegebenen Termin stattfinden kann, werden die angemeldeten Teilnehmer nach Möglichkeit rechtzeitig über den Ausfall des Kurses benachrichtigt. Dann entstehen zwei Möglichkeiten: Entweder werden die bereits gezahlten Kursgebühren zurückgezahlt, oder wird der Beginn des Kurses verschoben. Sie können uns persönlich, telefonisch, per E-mail oder Fax über ihre Entscheidung informieren. Ein Anspruch auf Ausfallgeld oder Entschädigung sind ausgeschlossen.</li>
						<li>Durch Krankheit der Lehrkraft, gesetzliche Feiertage oder interne Schuldispositionen ausfallende Kurstage werden nachgeholt.</li>
					</ol>
				</div>
			</div>
		</Layout>
	);
};

export default Gtc;
