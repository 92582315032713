import React from "react";
import {Link} from "react-router-dom";

import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";

import "./Course.scss";

const Course = () => {
	return (
		<Layout heading={<Banner title="B2/C1 Kurse" additional={<span>Deutschkurs</span>} className="course-banner"/>} menuId={2}>
			<div className="course default-margin">
				<div>
					<p>
						<b>Insgesamt 200 Unterrichtseinheiten, Dauer 2 Monate</b>
					</p>
				</div>
				<div className="row course-date">
					<div className="col-12">
						<div className="text-primary">Unsere Kurse finden Montag bis Freitag statt</div>
					</div>
					<div className="col-4 col-sm-3 col-md-2 col-lg-2">Nachmittags</div><div className="col-6">14:00 – 18:00 Uhr</div>
				</div>
				<div className="description">
					<p>
						Wir bieten für Selbstzahler Vorbereitungskurse auf die DSH-, TestDaf- und Studienkollegsprüfungen an. Dies richten sich an Studierende, die ein Studium in Deutschland aufnehmen möchten und hierfür einen Nachweis ihrer deutschen Sprachkenntnisse auf fortgeschrittenem Niveau brauchen.
					</p>
					<p>
						Die Vorbereitungskurse werden von Herrn Dr. Özen durchgeführt. Neben speziellen Materialien für das Training der einzelnen sprachlichen Fertigkeiten werden auch DSH-Prüfungen unterschiedlicher Universitäten aus den vergangenen Jahren eingesetzt. Es werden gezielte Übungen durchgeführt, die behilflich bei der Zeitverteilung während des Ablegens der realen DSH-Prüfung sein können. Der Kurs endet mit einer DSH-Probeprüfung, so dass jeder Teilnehmer das Niveau seiner Deutschkenntnisse testen lassen kann.
					</p>
				</div>
				<p>Für weitere Informationen nehmen Sie bitte <Link to="/contact">Kontakt</Link> mit uns auf.</p>
			</div>
		</Layout>
	);
};

export default Course;
