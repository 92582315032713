import React from "react";
import Layout from "../../components/Layout/Layout.component";
import Banner from "../../components/Banner/Banner.component";

import Bamf from "../../assets/images/bamf.svg";

import "./Integration.scss";

const Integration = () => {


	return (
		<Layout heading={<Banner title="Integrationskurse" className="integration-banner" additional={<>
			<span>Basierend auf dem Konzept vom</span>
			<span className="bamf-wrapper">
				<img alt="BAMF" src={Bamf} />
			</span>
		</>} />} menuId={1}>
			<div className="integration default-margin">
				<div className="description">
					<span>Die Integrationskurse basieren auf der Konzeption vom Bundesamt für Migration und Flüchtlinge (BAMF) und sind an den Lernvorrausetzungen nicht mehr schulpflichtiger Zugewanderter ausgerichtet.</span>
				</div>
				<div className="detail-box">
					<span className="text-primary">Allgemeine Integrationskurse bestehen aus 700 Unterrichtseinheiten (Dauer 7 Monate):</span>
					<div className="row lesson-units">
						<div className="col col-sm-4 col-md-3">
							<p>Basiskurs</p>
							<ul>
								<li>A1.1 - 100 UE</li>
								<li>A1.2 - 100 UE</li>
								<li>A2.1 - 100 UE</li>
							</ul>
						</div>
						<div className="col col-sm-4 col-md-3">
							<p>Aufbaukurs</p>
							<ul>
								<li>A2.2 - 100 UE</li>
								<li>B1.1 - 100 UE</li>
								<li>B2.1 - 100 UE</li>
							</ul>
						</div>
						<div className="col-break col-break-sm"/>
						<div className="col-6 col-sm-4 col-md-3">
							<p>Orientierungskurs</p>
							<ul>
								<li>100 UE</li>
							</ul>
						</div>
					</div>
					<div className="ue-info"><span>UE = Unterrichtseinheit</span><br/><span>Jede Unterrichtseinheit beträgt 45 Minuten</span></div>
					<div className="row">
						<div className="col-12">
							<div className="text-primary">Unsere Kurse finden Montag bis Freitag statt</div>
						</div>
						<div className="col-5 col-md-3 col-lg-2">Vormittags</div><div className="col-7">08:30 – 12:45 Uhr</div><div className="col-break"/>
						<div className="col-5 col-md-3 col-lg-2">Nachmittags</div> <div className="col-7"> 13:30 – 17:30 Uhr</div>
					</div>
				</div>
				<div className="description">
					<p>
						<span className="text-primary"><b>Integrationskurse</b></span>
						<span>
							Integrationskurse dienen dem Erwerb ausreichender deutscher Sprachkenntnisse im Sinne der Integrationsziele und sollen über die Zwischenstufen in Basis- und Aufbausprachkursen zum Niveau-B1 führen. Das Niveau B1 wird auf der Skala des Gemeinsamen Europäischen Referenzrahmens (GER) als erste Stufe der selbstständigen Sprachverwendung beschrieben.
						</span>
					</p>
					<p>
						<span className="text-primary"><b>Orientisierungskurse</b></span>
						<span>
							Die Orientierungskurse zielen auf eine Auseinandersetzung der Migrantinnen/Migranten mit den grundlegenden Werten der Gesellschaft, der Rechtsordnung, der Geschichte und Kultur, die das Zurechtfinden in der Gesellschaft und den positiven Umgang mit der neuen Lebenswirklichkeit fördern soll.
						</span>
					</p>
					<p>
						<span className="text-primary"><b>Alphabetisierungskurse</b></span>
						<span>
							Maximal 1200 Unterrichtseinheiten, Dauer 12 Monate und schließen ebenfalls mit einem Orientierungskurs ab. Der Alphabetisierungskurs hilft den Menschen, die in ihren Heimatsländern nicht die Möglichkeit hatten, Lesen und Schreiben zu lernen. Nicht nur in der deutschen Bevölkerung, sondern auch bei Zugewanderten gibt es Menschen, die nicht ausreichend lesen und schreiben können. Viele von ihnen müssen eine zusätzliche Hürde bewältigen: Sie sollen nicht nur Deutsch sprechen, sondern gleichzeitig in lateinischer Schrift lesen und schreiben lernen. Die Alphabetisierungskurse helfen diesen Menschen dabei.
						</span>
					 </p>
				</div>
			</div>
		</Layout>
	);
};

export default Integration;
