import React from "react";
import SideNavBar from "../Navbar/SideNavBar/SideNavBar.component";

import "./Layout.scss";

const Layout = ({children, heading, menuId}) => {
  	return (
		<>
			<div className="heading">
				{heading}
			</div>
			<div className="side-bar-container close" id="sideBarContainer">
				<SideNavBar menuId={menuId} />
			</div>
		  	<div className="container">
				<div>
			  		<div className="content-container">
						{children}
			  		</div>
				</div>
		  	</div>
		</>
	);
};

export default Layout;
